import React, { useContext, useEffect, useState } from "react";
import { Box, Typography, TextField, Button, Grid } from "@mui/material";
import { API_ADMIN_SERVICE_BACKEND, API_SERVICE_BACKEND } from "src/config";
import { getSessionStorage } from "src/utils/storage";
import { WhiteLabelConfigurationContext } from "src/contexts/ClientConfiguration";
import toast from "react-hot-toast";
import { Seo } from "src/components/seo";

const SeoSetting = () => {
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    tagLine: "",
  });
  const [isFormDataChange, setIsFormDataChange] = useState(false);

  const [defaultSeoData, setDefaultSeoData] = useState({});
  const email = getSessionStorage("userEmail");
  const { whiteLableId, getAllWhiteLabelClientConfigurations, metaTitle, companyName } = useContext(
    WhiteLabelConfigurationContext
  );
  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setIsFormDataChange(true);
  };

  // Fetch data from API to populate form
  const getAllWhiteLabelClients = async () => {
    try {
      const response = await fetch(
        `${API_SERVICE_BACKEND}/getwhiteLabelConfig/${whiteLableId}`
      );
      const data = await response.json();

      const defaultSeo = {
        title: data.data.seo?.title || "",
        description: data.data.seo?.description || "",
        tagLine: data.data.seo?.tagLine || "",
      };

      setFormData(defaultSeo);
      setDefaultSeoData(data.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getAllWhiteLabelClients();
  }, [whiteLableId]);

  const handleSubmit = async () => {
    const requestData = {
      email: email,
      seo: {
        title: formData.title,
        description: formData.description,
        tagLine: formData.tagLine,
      },
      info: {
        companyName: defaultSeoData.info.companyName,
        logoUrl: defaultSeoData.info.logoUrl,
        favIcon: defaultSeoData.info.favIcon,
      },
      theme: {
        primaryTextColor: defaultSeoData.theme.primaryTextColor,
        secondaryTextColor: defaultSeoData.theme.secondaryTextColor,
        primaryBrandColor: defaultSeoData.theme.primaryBrandColor,
        secondaryBrandColor: defaultSeoData.theme.secondaryBrandColor,
      },
    };

    try {
      const response = await fetch(
        `${API_SERVICE_BACKEND}/handleWhiteLabelConfig`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        }
      );

      const data = await response.json();

      if (response.status === 200) {
        toast.success(response.message ?? "SEO updated successfully!", {
          autoClose: 3000,
        });
        getAllWhiteLabelClients();
        getAllWhiteLabelClientConfigurations(whiteLableId);
      } else {
        // Error
        toast.error(response.message ?? "Failed to connect", {
          autoClose: 3000,
        });
      }
    } catch (error) {
      console.error("Error submitting SEO data:", error);
    }
  };

  useEffect(() => {
    const debounce = setTimeout(() => {
      if (isFormDataChange) {
        handleSubmit();
        setIsFormDataChange(false);
      }
    }, 2000);

    return () => clearTimeout(debounce);

  }, [formData, isFormDataChange]);

  const handleReset = async () => {
    // setFormData({
    //     title: defaultSeoData?.seo?.title || "",
    //     description: defaultSeoData?.seo?.description || "",
    //     tagLine: defaultSeoData?.seo?.tagLine || "",
    // });
    const requestData = {
      email: email,
      seo: {
        title: "B2B Rocket",
        description: "description",
        tagLine: "tagLine",
      },
      info: {
        companyName: defaultSeoData.info.companyName,
        logoUrl: defaultSeoData.info.logoUrl,
        favIcon: defaultSeoData.info.favIcon,
      },
      theme: {
        primaryTextColor: defaultSeoData.theme.primaryTextColor,
        secondaryTextColor: defaultSeoData.theme.secondaryTextColor,
        primaryBrandColor: defaultSeoData.theme.primaryBrandColor,
        secondaryBrandColor: defaultSeoData.theme.secondaryBrandColor,
      },
    };

    try {
      const response = await fetch(
        `${API_SERVICE_BACKEND}/handleWhiteLabelConfig`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        }
      );

      const data = await response.json();

      if (response.status === 200) {
        toast.success(response.message ?? "SEO reset successfully!", {
          autoClose: 3000,
        });
        getAllWhiteLabelClients();
        getAllWhiteLabelClientConfigurations(whiteLableId);
      } else {
        // Error
        toast.error(response.message ?? "Failed to connect", {
          autoClose: 3000,
        });
      }
    } catch (error) {
      console.error("Error submitting SEO data:", error);
    }
  };

  const pageTitle = `Partner/White Label: SEO Settings - ${metaTitle || companyName }`;

  return (
    <>
    <Seo title={pageTitle}/>
    <Box sx={{width: {xs: "100%", md:"50%"}}}>
      {/* <Box sx={{ display: "flex", alignItems: "center", my: "20px" }}>
        <Typography
          sx={{ fontSize: "32px", fontWeight: 700, color: "#111927" }}
        >
          SEO Setting
        </Typography>
      </Box> */}

      {/* SEO Title Field */}
      <TextField
        required
        id="title"
        label="Meta Title"
        variant="outlined"
        fullWidth
        name="title"
        value={formData.title}
        onChange={handleChange}
        sx={{ mb: 3 }}
        className="glass-input"
      />

      {/* SEO Description Field */}
      <TextField
        required
        id="description"
        label="Meta Description"
        variant="outlined"
        fullWidth
        name="description"
        value={formData.description}
        onChange={handleChange}
        sx={{ mb: 3 }}
        className="glass-input"
      />

      {/* SEO Tagline Field */}
      <TextField
        required
        id="tagLine"
        label="Meta Tagline"
        variant="outlined"
        fullWidth
        name="tagLine"
        value={formData.tagLine}
        onChange={handleChange}
        sx={{ mb: 3 }}
        className="glass-input"
      />

      {/* <Grid container spacing={2}> */}
        {/* <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            sx={{ marginTop: "20px", width: "100%" }}
            onClick={handleReset}
          >
            Reset SEO
          </Button>
        </Grid> */}

        {/* Update SEO Button */}
        {/* <Grid item xs={6}>
          <Button
            variant="contained"
            color="primary"
            sx={{ marginTop: "20px", width: "100%" }}
            onClick={handleSubmit}
          >
            Update SEO
          </Button>
        </Grid> */}
      {/* </Grid> */}
    </Box>
    </>
  );
};

export default SeoSetting;
