import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { WhiteLabelConfigurationContext } from "src/contexts/ClientConfiguration";

const MetaTags = ({ settings, theme }) => {
  const metaFields = [
    { name: "title", key: "META_TITLE" },
    { name: "description", key: "META_DESCRIPTION" },
    { name: "tagline", key: "TAGLINE" },
  ];
  const {
    metaTitle,
    metaDescription,
    tagline,
    faviconUrl,
    subUserStatus,
    whiteLableStatus,
  } = useContext(WhiteLabelConfigurationContext);
 const [status, setStatus] = useState(false);
  useEffect(() => {
    setStatus((whiteLableStatus || subUserStatus))
  }, [whiteLableStatus , subUserStatus]); 
  return (
    <Helmet>
      {/* Color scheme and theme color meta tags */}
      <meta name="title" content={metaTitle} />
      <title>{metaTitle}</title>
      <meta name="description" content={metaDescription} />
      <meta name="tagline" content={tagline} />
      <link rel="icon" type="image/png" href={faviconUrl} />
      <link rel="icon" type="image/png" href={faviconUrl} sizes="16x16" />
      <link rel="icon" type="image/png" href={faviconUrl} sizes="32x32" />

      <meta name="color-scheme" content={settings?.paletteMode} />
      <meta name="theme-color" content={theme?.palette?.neutral[900]} />

      {(status && !subUserStatus ) && (

        <>
          <script>
            {`(function (w, d, s, l, i) {
              w[l] = w[l] || [];
              w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
              var f = d.getElementsByTagName(s)[0],
                j = d.createElement(s);
              j.async = true;
              j.src = "https://load.gtm.b2brocket.ai/dsbfveigibuoe.js?" + i;
              f.parentNode.insertBefore(j, f);
            })(
              window,
              document,
              "script",
              "dataLayer",
              "2ygq=aWQ9R1RNLVRWTlFWMzg3&page=3"
            )`}
          </script>
          <script type="text/javascript">
            {`(function () {
              var gs = document.createElement("script");
              gs.src = "https://get.b2brocket.ai/pr/js";
              gs.type = "text/javascript";
              gs.async = "true";
              gs.onload = gs.onreadystatechange = function () {
                var rs = this.readyState;
                if (rs && rs != "complete" && rs != "loaded") return;
                try {
                  growsumo._initialize("pk_0y58SLEcGijmKUgXsFfZovNDqEHe0PZo");
                  if (typeof growsumoInit === "function") {
                    growsumoInit();
                  }
                } catch (e) {}
              };
              var s = document.getElementsByTagName("script")[0];
              s.parentNode.insertBefore(gs, s);
            })()`}
          </script>
          
        </>
      )}
      {/* Dynamic meta tags based on the whiteLabelConfigurationData */}
      {/* {metaFields.map(({ name, key }) => {
          const content = whiteLabelConfigurationData?.[key] ?? null;
          return content && (
            <meta key={name} name={name} content={content} />
          );
        })} */}
    </Helmet>
  );
};
export default MetaTags;