import { useContext, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import File04Icon from "@untitled-ui/icons-react/build/esm/File04";
import { Box, Button, Divider, Drawer, Stack, SvgIcon, Tooltip, Typography, alpha } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Logo } from "src/components/logo";
import { RouterLink } from "src/components/router-link";
import { Scrollbar } from "src/components/scrollbar";
import { usePathname } from "src/hooks/use-pathname";
import { paths } from "src/paths";
import { TenantSwitch } from "../tenant-switch";
import { MobileNavSection } from "./mobile-nav-section";
import SideNavFooter from "../side-nav-footer";
import B2BRocketLogo from "../../../assets/blue icon square.png";
import { SideNavSection } from "../vertical-layout/side-nav-section";
import { WhiteLabelConfigurationContext } from "src/contexts/ClientConfiguration";
import SideNavItemNew from "../vertical-layout/side-nav-item-new";

const MOBILE_NAV_WIDTH = 280;

const useCssVars = (color) => {
  const theme = useTheme();

  return useMemo(() => {
    switch (color) {
      // Blend-in and discreet have no difference on mobile because
      // there's a backdrop and differences are not visible
      case "blend-in":
      case "discreet":
        if (theme.palette.mode === "dark") {
          return {
            "--nav-bg": theme.palette.background.default,
            "--nav-color": theme.palette.neutral[100],
            "--nav-logo-border": theme.palette.neutral[700],
            "--nav-section-title-color": theme.palette.neutral[400],
            "--nav-item-color": theme.palette.neutral[400],
            "--nav-item-hover-bg": "rgba(255, 255, 255, 0.04)",
            "--nav-item-active-bg": "rgba(255, 255, 255, 0.04)",
            "--nav-item-active-color": theme.palette.text.primary,
            "--nav-item-disabled-color": theme.palette.neutral[600],
            "--nav-item-icon-color": theme.palette.neutral[500],
            "--nav-item-icon-active-color": theme.palette.primary.main,
            "--nav-item-icon-disabled-color": theme.palette.neutral[700],
            "--nav-item-chevron-color": theme.palette.neutral[700],
            "--nav-scrollbar-color": theme.palette.neutral[400],
          };
        } else {
          return {
            "--nav-bg": theme.palette.background.default,
            "--nav-color": theme.palette.text.primary,
            "--nav-logo-border": theme.palette.neutral[100],
            "--nav-section-title-color": theme.palette.neutral[400],
            "--nav-item-color": theme.palette.text.secondary,
            "--nav-item-hover-bg": theme.palette.action.hover,
            "--nav-item-active-bg": theme.palette.action.selected,
            "--nav-item-active-color": theme.palette.text.primary,
            "--nav-item-disabled-color": theme.palette.neutral[400],
            "--nav-item-icon-color": theme.palette.neutral[400],
            "--nav-item-icon-active-color": theme.palette.primary.main,
            "--nav-item-icon-disabled-color": theme.palette.neutral[400],
            "--nav-item-chevron-color": theme.palette.neutral[400],
            "--nav-scrollbar-color": theme.palette.neutral[900],
          };
        }

      case "evident":
        if (theme.palette.mode === "dark") {
          return {
            "--nav-bg": theme.palette.neutral[800],
            "--nav-color": theme.palette.common.white,
            "--nav-logo-border": theme.palette.neutral[700],
            "--nav-section-title-color": theme.palette.neutral[400],
            "--nav-item-color": theme.palette.text.primary, // theme.palette.neutral[400],
            "--nav-item-hover-bg": theme.palette.primary.alpha12,
            "--nav-item-active-bg": theme.palette.primary.main, // "rgba(255, 255, 255, 0.04)",
            "--nav-item-active-color": theme.palette.common.white,
            "--nav-item-disabled-color": theme.palette.neutral[500],
            "--nav-item-icon-color": theme.palette.text.primary,
            "--nav-item-icon-active-color": theme.palette.primary.contrastText,
            "--nav-item-icon-disabled-color": theme.palette.neutral[500],
            "--nav-item-chevron-color": theme.palette.neutral[600],
            "--nav-scrollbar-color": theme.palette.neutral[400],
          };
        } else {
          return {
            "--nav-bg": theme.palette.neutral[800],
            "--nav-color": theme.palette.common.white,
            "--nav-logo-border": theme.palette.neutral[700],
            "--nav-section-title-color": theme.palette.neutral[400],
            "--nav-item-color": theme.palette.text.primary, // theme.palette.neutral[400],
            "--nav-item-hover-bg": theme.palette.primary.alpha12,
            "--nav-item-active-bg": theme.palette.primary.main, // "rgba(255, 255, 255, 0.04)",
            "--nav-item-active-color": theme.palette.primary.contrastText,
            "--nav-item-disabled-color": theme.palette.neutral[500],
            "--nav-item-icon-color": theme.palette.text.primary, // theme.palette.neutral[900],
            "--nav-item-icon-active-color": theme.palette.primary.contrastText,
            "--nav-item-icon-disabled-color": theme.palette.neutral[500],
            "--nav-item-chevron-color": theme.palette.neutral[600],
            "--nav-scrollbar-color": theme.palette.neutral[400],
          };
        }

      default:
        return {};
    }
  }, [theme, color]);
};

export const MobileNav = (props) => {
  const { color = "evident", open, onClose, sections = [] } = props;
  const pathname = usePathname();
  const cssVars = useCssVars(color);
  const [expand, setExpand] = useState("");
  const {logoUrl,showCompanyName ,companyName} = useContext(WhiteLabelConfigurationContext);
  return (
    <Drawer
      anchor="left"
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          ...cssVars,
          // backgroundColor: "var(--nav-bg)",
          // color: "var(--nav-color)",
          backgroundColor: "rgba(255, 255, 255, 1)", //'#050C46',
          borderRightColor: "var(--nav-border-color)",
          borderRightStyle: "solid",
          borderRightWidth: 1,
          color: "var(--nav-color)",
          width: MOBILE_NAV_WIDTH, //theme => `calc(${theme.spacing(8)} + 0px)`, // MOBILE_NAV_WIDTH,
        },
        className: "glass-wrapper"
      }}
      variant="temporary"
    >
      <Scrollbar
        sx={{
          height: "100%",
          "& .simplebar-content": {
            height: "100%",
          },
          "& .simplebar-scrollbar:before": {
            background: "var(--nav-scrollbar-color)",
          },
        }}
      >
        <Stack sx={{ height: "100%" }}>
          <Stack alignItems="center" direction="row"  height="90px" spacing={2} sx={{ p: "12px" }}>
            {/* <Tooltip
              title={
                <Typography color="inherit" variant="h6" sx={{ p: 1 }}>
                  {companyName}
                </Typography>
              }
              placement="right-start"
              arrow
              enterTouchDelay={"300ms"}
            > */}
              <img
                src={logoUrl}
                alt={`${companyName} Logo`}
                style={{
                  width: !showCompanyName ? "auto" : "40px",
                  maxWidth: "210px",
                  height: "40px",
                  objectFit: "contain",
                }}
              />
            {/* </Tooltip> */}
            <TenantSwitch sx={{ flexGrow: 1 }} />
          </Stack>
          <Stack
            component="nav"
            sx={{
              px: "12px",
              flexGrow: 1,
              gap: 1
            }}
          >
            {/* {sections.map((section, index) => (
              <MobileNavSection
                sx={{
                  padding: "0px 16px",
                  gap: "10px",
                  listStyleType: 'none',
                  fontFamily: 'DM Sans'
                }}
                items={section.items}
                key={index}
                pathname={pathname}
                subheader={section.subheader}
              />
            ))} */}
            {/* {sections.map((section, index) => (
              <SideNavSection
                open={true}
                sx={{
                  padding: "0px 4px",
                  gap: "10px",
                  margin: 0,
                  listStyleType: "none",
                  fontFamily: "DM Sans",
                }}
                items={section.items}
                key={index}
                pathname={pathname}
                path={section.path}
                subheader={section.subheader}
                icon={section.icon}
                expand={expand}
                setExpand={setExpand}
                cssVariables={cssVars}
              />
            ))} */}
            {sections?.map((section, index) => (
              <>
                <Stack key={index}>
                  {section?.items?.map((navItem, idx) => {
                    const childPaths = navItem.childPaths;
                    const isActive = childPaths
                      ? childPaths?.length > 0
                        ? childPaths?.concat(navItem.path)?.some((path) => pathname === path)
                        : pathname === navItem.path
                      : pathname === navItem.path;
                    return (
                      <SideNavItemNew
                        active={isActive}
                        disabled={navItem.disabled}
                        external={navItem.external}
                        icon={navItem.icon}
                        path={navItem.path}
                        title={navItem.title}
                        options={navItem.options || []}
                        index={idx}
                        expanded={true}
                      />
                    )
                  })}
                </Stack>
                {(index !== sections.length - 1) && <Divider sx={{borderColor: theme => alpha(theme.palette.text.primary, 0.2)}} />}
              </>
            ))}
          </Stack>
          <Stack
            spacing={1}
            sx={{
              my: 1
            }}
          >
            <SideNavFooter open={true} expand={expand} setExpand={setExpand} />
          </Stack>
          {/* <Box sx={{ p: 3 }}>
            <Typography
              color="neutral.400"
              variant="subtitle1"
            >
              Need help?
            </Typography>
            <Typography
              color="neutral.400"
              sx={{ mb: 2 }}
              variant="body2"
            >
              Please check our docs.
            </Typography>
            <Button
              component="a"
              fullWidth
              href={paths.docs}
              startIcon={(
                <SvgIcon>
                  <File04Icon />
                </SvgIcon>
              )}
              target="_blank"
              variant="contained"
            >
              Documentation
            </Button>
          </Box> */}
        </Stack>
      </Scrollbar>
    </Drawer>
  );
};

MobileNav.propTypes = {
  color: PropTypes.oneOf(["blend-in", "discreet", "evident"]),
  onClose: PropTypes.func,
  open: PropTypes.bool,
  sections: PropTypes.array,
};
