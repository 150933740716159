import { useContext, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import File04Icon from "@untitled-ui/icons-react/build/esm/File04";
import {
  Box,
  Button,
  Drawer as MuiDrawer,
  IconButton,
  styled,
  Stack,
  SvgIcon,
  Typography,
  Divider,
  alpha,
  Tooltip
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Logo } from "src/components/logo";
import { RouterLink } from "src/components/router-link";
import { Scrollbar } from "src/components/scrollbar";
import { usePathname } from "src/hooks/use-pathname";
import { paths } from "src/paths";
import { TenantSwitch } from "../tenant-switch";
import { SideNavSection } from "./side-nav-section";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";

// import LeadinglyLogo from '../../../assets/MarkLogo.png'
import B2BRocketLogo from "../../../assets/blue icon square.png";

import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import SideNavFooter from "../side-nav-footer";
import { WhiteLabelConfigurationContext } from "src/contexts/ClientConfiguration";
import { WhiteLabelMenu } from "./WhiteLabelMenu";
import SideNavItemNew from "./side-nav-item-new";

const SIDE_NAV_WIDTH = 280;
const openedMixin = (theme) => ({
  width: SIDE_NAV_WIDTH,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(8)} + 0px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 0px)`,
  },
});
const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: SIDE_NAV_WIDTH,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const useCssVars = (color) => {
  const theme = useTheme();

  return useMemo(() => {
    switch (color) {
      case "blend-in":
        if (theme.palette.mode === "dark") {
          return {
            "--nav-bg": theme.palette.background.default,
            "--nav-color": theme.palette.neutral[100],
            "--nav-border-color": theme.palette.neutral[700],
            "--nav-logo-border": theme.palette.neutral[700],
            "--nav-section-title-color": theme.palette.neutral[400],
            "--nav-item-color": theme.palette.neutral[400],
            "--nav-item-hover-bg": "rgba(255, 255, 255, 0.04)",
            "--nav-item-active-bg": "rgba(255, 255, 255, 0.04)",
            "--nav-item-active-color": theme.palette.text.primary,
            "--nav-item-disabled-color": theme.palette.neutral[600],
            "--nav-item-icon-color": theme.palette.neutral[500],
            "--nav-item-icon-active-color": theme.palette.primary.main,
            "--nav-item-icon-disabled-color": theme.palette.neutral[700],
            "--nav-item-chevron-color": theme.palette.neutral[700],
            "--nav-scrollbar-color": theme.palette.neutral[400],
          };
        } else {
          return {
            "--nav-bg": theme.palette.background.default,
            "--nav-color": theme.palette.text.primary,
            "--nav-border-color": theme.palette.neutral[100],
            "--nav-logo-border": theme.palette.neutral[100],
            "--nav-section-title-color": theme.palette.neutral[400],
            "--nav-item-color": theme.palette.text.secondary,
            "--nav-item-hover-bg": theme.palette.action.hover,
            "--nav-item-active-bg": theme.palette.action.selected,
            "--nav-item-active-color": theme.palette.text.primary,
            "--nav-item-disabled-color": theme.palette.neutral[400],
            "--nav-item-icon-color": theme.palette.neutral[400],
            "--nav-item-icon-active-color": theme.palette.primary.main,
            "--nav-item-icon-disabled-color": theme.palette.neutral[400],
            "--nav-item-chevron-color": theme.palette.neutral[400],
            "--nav-scrollbar-color": theme.palette.neutral[900],
          };
        }

      case "discreet":
        if (theme.palette.mode === "dark") {
          return {
            "--nav-bg": theme.palette.neutral[900],
            "--nav-color": theme.palette.neutral[100],
            "--nav-border-color": theme.palette.neutral[700],
            "--nav-logo-border": theme.palette.neutral[700],
            "--nav-section-title-color": theme.palette.neutral[400],
            "--nav-item-color": theme.palette.neutral[400],
            "--nav-item-hover-bg": "rgba(255, 255, 255, 0.04)",
            "--nav-item-active-bg": "rgba(255, 255, 255, 0.04)",
            "--nav-item-active-color": theme.palette.text.primary,
            "--nav-item-disabled-color": theme.palette.neutral[600],
            "--nav-item-icon-color": theme.palette.neutral[500],
            "--nav-item-icon-active-color": theme.palette.primary.main,
            "--nav-item-icon-disabled-color": theme.palette.neutral[700],
            "--nav-item-chevron-color": theme.palette.neutral[700],
            "--nav-scrollbar-color": theme.palette.neutral[400],
          };
        } else {
          return {
            "--nav-bg": theme.palette.neutral[50],
            "--nav-color": theme.palette.text.primary,
            "--nav-border-color": theme.palette.divider,
            "--nav-logo-border": theme.palette.neutral[200],
            "--nav-section-title-color": theme.palette.neutral[500],
            "--nav-item-color": theme.palette.neutral[500],
            "--nav-item-hover-bg": theme.palette.action.hover,
            "--nav-item-active-bg": theme.palette.action.selected,
            "--nav-item-active-color": theme.palette.text.primary,
            "--nav-item-disabled-color": theme.palette.neutral[400],
            "--nav-item-icon-color": theme.palette.neutral[400],
            "--nav-item-icon-active-color": theme.palette.primary.main,
            "--nav-item-icon-disabled-color": theme.palette.neutral[400],
            "--nav-item-chevron-color": theme.palette.neutral[400],
            "--nav-scrollbar-color": theme.palette.neutral[900],
          };
        }

      case "evident":
        if (theme.palette.mode === "dark") {
          return {
            "--nav-bg": theme.palette.neutral[800],
            "--nav-color": theme.palette.common.white,
            "--nav-border-color": "transparent",
            "--nav-logo-border": theme.palette.neutral[700],
            "--nav-section-title-color": theme.palette.neutral[400],
            "--nav-item-color": theme.palette.text.primary, // theme.palette.neutral[400],
            "--nav-item-hover-bg": theme.palette.primary.alpha12,
            "--nav-item-active-bg": theme.palette.primary.main, // "rgba(255, 255, 255, 0.04)",
            "--nav-item-active-color": theme.palette.common.white,
            "--nav-item-disabled-color": theme.palette.neutral[500],
            "--nav-item-icon-color": theme.palette.text.primary,
            "--nav-item-icon-active-color": theme.palette.primary.contrastText,
            "--nav-item-icon-disabled-color": theme.palette.neutral[500],
            "--nav-item-chevron-color": theme.palette.neutral[600],
            "--nav-scrollbar-color": theme.palette.neutral[400],
          };
        } else {
          return {
            "--nav-bg": theme.palette.neutral[800],
            "--nav-color": theme.palette.common.white,
            "--nav-border-color": "transparent",
            "--nav-logo-border": theme.palette.neutral[700],
            "--nav-section-title-color": theme.palette.neutral[400],
            "--nav-item-color": theme.palette.text.primary, // theme.palette.neutral[400],
            "--nav-item-hover-bg": theme.palette.primary.alpha12,
            "--nav-item-active-bg": theme.palette.primary.main, // "rgba(255, 255, 255, 0.18)",
            "--nav-item-active-color": theme.palette.primary.contrastText, // theme.palette.common.white
            "--nav-item-disabled-color": theme.palette.neutral[500],
            "--nav-item-icon-color": theme.palette.text.primary, // theme.palette.neutral[900],
            "--nav-item-icon-active-color": theme.palette.primary.contrastText, //theme.palette.primary.main,
            "--nav-item-icon-disabled-color": theme.palette.neutral[500],
            "--nav-item-chevron-color": theme.palette.neutral[600],
            "--nav-scrollbar-color": theme.palette.neutral[400],
          };
        }

      default:
        return {};
    }
  }, [theme, color]);
};

export const SideNav = (props) => {
  const { color = "evident", sections = [], open, handleDrawerToggle } = props;
  const pathname = usePathname();
  const cssVars = useCssVars(color);
  const theme = useTheme();
  const [expand, setExpand] = useState("");
  const { logoUrl, companyName, showCompanyName } = useContext(
    WhiteLabelConfigurationContext
  );
  return (
    <>
      <IconButton
        onClick={handleDrawerToggle}
        sx={{
          position: "fixed",
          // position: "absolute",
          left: open ? "246px" : `calc(${theme.spacing(7)} + 3px)`,
          top: "30px",
          // zIndex: 1300,
          zIndex: 1201,
          borderRadius: "16px !important",
          backgroundColor: "#ffffff",
          // backgroundColor: "rgba(255, 255, 255, 1)",
          border: "1px solid rgba(224, 224, 229, 1)",
          color: theme.palette.text.primary,
          p: 0.2,
          "&:hover": {
            backgroundColor: "#ffffff",
          },
        }}
        className="glass-button-secondary"
      >
        {open ? <ChevronLeft /> : <ChevronRight />}
      </IconButton>

      <Drawer
        anchor="left"
        open={open}
        PaperProps={{
          sx: {
            ...cssVars,
            backgroundColor: "rgba(255, 255, 255, 1)", //'#050C46',
            borderRightColor: "var(--nav-border-color)",
            borderRightStyle: "solid",
            borderRightWidth: 1,
            color: "var(--nav-color)",
            width: SIDE_NAV_WIDTH,
            margin: "10px",
            height: "calc(100% - 20px)",
            borderRadius: "12px",
            border: "1px solid rgba(224, 224, 229, 1)",
            overflowX: "hidden"
          },
          className: "glass-wrapper"
        }}
        variant="permanent"
      >
        <Scrollbar
          sx={{
            height: "100%",
            "& .simplebar-content": {
              height: "100%",
            },
            "& .simplebar-scrollbar:before": {
              background: "var(--nav-scrollbar-color)",
            },
            "& .simplebar-track.simplebar-horizontal": {
              visibility: open ? "visible" : "hidden !important",
            },
          }}
        >
          <Stack sx={{ height: "100%" }}>
            <Stack
              alignItems="center"
              direction="row"
              //height="90px"
              spacing={2}
              sx={{ p: "12px" }}
            >
              {/* <Tooltip 
                title={
                  <Typography color="inherit" variant="h6" sx={{ p: 1 }}>
                    {companyName}
                  </Typography>
                }
                placement="right-start"
                arrow
              > */}
                <img
                  src={logoUrl}
                  alt={`${companyName} Logo`}
                  style={{
                    width: open && !showCompanyName ? "auto" : "40px",
                    maxWidth: "210px",
                    height: "40px",
                    objectFit: "contain",
                  }}
                />
              {/* </Tooltip> */}
              {open && <TenantSwitch sx={{ flexGrow: 1 }} />}
            </Stack>
            <Stack
              component="nav"
              sx={{
                flexGrow: 1,
                gap: 1,
                px: "12px"
              }}
              // onMouseOver={(e) => !open && props.handleDrawerToggle(e, true)}
              // onMouseLeave={(e) => open && props.handleDrawerToggle(e, false)}
              // onClick={(e) => !open && props.handleDrawerToggle(e, true)}
              // onMouseLeave={(e) => open && props.handleDrawerToggle(e, false)}
            >
              {/* {sections.map((section, index) => (
                <SideNavSection
                  open={open}
                  sx={{
                    padding: "0px 4px",
                    gap: "10px",
                    margin: 0,
                    listStyleType: "none",
                    fontFamily: "DM Sans",
                  }}
                  items={section.items}
                  key={index}
                  index={index}
                  pathname={pathname}
                  path={section.path}
                  subheader={section.subheader}
                  icon={section.icon}
                  expand={expand}
                  setExpand={setExpand}
                  cssVariables={cssVars}

                  // handleDrawerToggle={handleDrawerToggle}
                />
              ))} */}
              {sections?.map((section, index) => (
                <>
                  <Stack key={index}>
                    {section?.items?.map((navItem, idx) => {
                      const childPaths = navItem.childPaths;
                      const isActive = childPaths
                        ? childPaths?.length > 0
                          ? childPaths?.concat(navItem.path)?.some((path) => pathname === path)
                          : pathname === navItem.path
                        : pathname === navItem.path;
                      return (
                        <SideNavItemNew
                          active={isActive}
                          disabled={navItem.disabled}
                          external={navItem.external}
                          icon={navItem.icon}
                          path={navItem.path}
                          title={navItem.title}
                          options={navItem.options || []}
                          index={idx}
                          expanded={open}
                        />
                      )
                    })}
                  </Stack>
                  {(index !== sections.length - 1) && <Divider />}
                </>
              ))}
              {/*             
              <WhiteLabelMenu /> */}
            </Stack>
            <Stack
              spacing={1}
              sx={{
                //px: 2,
                my: 1,
              }}
              // onClick={(e) => !open && props.handleDrawerToggle(e, true)}
              // onMouseLeave={(e) => open && props.handleDrawerToggle(e, false)}
            >
              <SideNavFooter
                open={open}
                expand={expand}
                setExpand={setExpand}
              />
            </Stack>
            {/* <Box sx={{ p: 3 }}>
            <Typography variant="subtitle1">
              Want Upgrade?
            </Typography>
            <Typography
              color="neutral.400"
              sx={{ mb: 2 }}
              variant="body2"
            >
              Check out pur plans
            </Typography>
            <Button
              component="a"
              fullWidth
              href={paths.docs}
              startIcon={(
                <SvgIcon>
                  <AddShoppingCartIcon />
                </SvgIcon>
              )}
              target="_blank"
              variant="contained"
            >
              Upgrade
            </Button>
          </Box> */}
          </Stack>
        </Scrollbar>
      </Drawer>
    </>
  );
};

SideNav.propTypes = {
  color: PropTypes.oneOf(["blend-in", "discreet", "evident"]),
  sections: PropTypes.array,
};
