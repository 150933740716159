import React, { useEffect, useState } from "react";
import "@material-ui/core/styles";
import {
  Button,
  Card,
  CardContent,
  Divider,
  Stack,
  Switch,
  Typography,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Grid,
  CircularProgress,
  useTheme
} from "@mui/material";
import {
  API_SERVICE_BACKEND_2,
  APP_TIMEZONE,
} from "src/config";
import moment from "moment-timezone";
import "../style.css";
import LinkIcon from "src/components/logos/linkIcon.png";
import Linkbroken from "src/components/logos/linkBroken.png";
import { useAuth } from "src/contexts/auth/firebase-context";
import { getServerDateTime } from "src/pages/dashboard/customers/allCustomers";
import { DifferentOwners } from "./owners"
import { Person } from "@mui/icons-material";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import ZapierIntegrationSettings from "./integrations/zapier"
import PipedriveIntegrationSettings from "./integrations/pipedrive"
import SalesforceIntegrationSettings from './integrations/salesforce'
import { AiOutlineLink } from "react-icons/ai";
import { PiLinkBreakBold } from "react-icons/pi";
import { MdOutlineHistory } from "react-icons/md";
import LogDrawer from "./LogDrawer";


export const INTEGRATIONTYPE = Object.freeze({
  HUBSPOT: "hubspot",
  ZOHO: "zoho",
  ZAPIER: "zapier",
  SALESFORCE: "salesforce"
})

const HUBSPOTOWNERASSIGNMENTTYPE = Object.freeze({
  SEPARATE: "SEPARATE",
  DEFAULT: "DEFAULT"
})

const IntegrationSettings = (props) => {

  const {
    email,
    section9Ref,
    userCampaigns,
    loadUserCampaigns,
    userCampaignLoading
  } = props;

  const { user } = useAuth();
  const theme = useTheme();

  const [isZohoAuto, setIsZohoAuto] = useState(false);
  const [isHubspotAuto, setIsHubspotAuto] = useState(false);
  const [hasZohoIntegration, setHasZohoIntegration] = useState(false);
  const [hasHubSpotIntegration, setHasHubSpotIntegration] = useState(false);

  const [zohoExpired, setZohoExpired] = useState(false);
  const [hubspotExpired, setHubSpotExpired] = useState(false);

  const [zohoIntegration, setZohoIntegration] = useState({});
  const [hubSpotIntegration, setHubSpotIntegration] = useState({});
  const [zohoAutoExecution, setZohoAutoExecution] = useState();
  const [hubspotAutoExecution, setHubspotAutoExecution] = useState();
  const [hubspotUsers, setHubspotUsers] = useState()

  const [hubspotOwnerAssignment, sethubspotOwnerAssignment] = useState(HUBSPOTOWNERASSIGNMENTTYPE.DEFAULT)
  const [selectedOwner, setSelectedOwner] = useState('')

  const [Baropen, setBarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleDisconnect = async (userEmail, type) => {
    // console.log(userEmail, type, "comingData");
    try {
      const response = await fetch(
        `${API_SERVICE_BACKEND_2}/removeIntegration`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userEmail: userEmail,
            type: type,
          }),
        }
      );
      // console.log(response, "responsedel");
      if (response.ok) {
        type === INTEGRATIONTYPE.HUBSPOT
          ? setHasHubSpotIntegration(false)
          : setHasZohoIntegration(false);
        // console.log(response, "resMsg");

        if (type === INTEGRATIONTYPE.HUBSPOT) {
          setHasHubSpotIntegration(false);
          setHubSpotExpired(false);
          setHubSpotIntegration(null);
        } else if (type === INTEGRATIONTYPE.ZOHO) {
          setHasZohoIntegration(false);
          setZohoExpired(false);
          setZohoIntegration(null);
        }
      } else {
        const errorData = await response.json();
        console.error(`Error: ${errorData.error}`);
      }
    } catch (error) {
      console.error("Internal Server Error:", error);
    }
  };

  const updateIntegrationAutoSyncFlag = async (email, type, isAuto) => {
    try {
      await fetch(`${API_SERVICE_BACKEND_2}/updateIntegration`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          isAuto: isAuto,
          userEmail: email,
          type: type,
        }),
      });
    } catch (error) {
      console.error("Error updating isAuto:", error);
    }
  };

  const initiateZohoOAuth = () => {
    // Replace 'YOUR_CLIENT_ID' with the actual Client ID obtained from the Zoho Developer Console
    const clientId = "1000.9VUAZN3TVQ0OSAOQSXUNGBHC72RRWA";
    const redirectUri = `${API_SERVICE_BACKEND_2}/zoho-callback`;


    const queryParams = {
      access_type: "offline",
      prompt: "consent",
      response_type: "code",
      client_id: clientId,
      redirect_uri: redirectUri,
      scope: "ZohoCRM.modules.ALL",
    };

    const oauthUrl = `https://accounts.zoho.com/oauth/v2/auth?${new URLSearchParams(
      queryParams
    ).toString()}`;
    console.log(oauthUrl);
    // Redirect the user to Zoho for authorization
    window.location.replace(oauthUrl);
  };

  const changeOwnerAssignmentStatus = (value) => {
    sethubspotOwnerAssignment(value)
    if (value === HUBSPOTOWNERASSIGNMENTTYPE.SEPARATE) {
      loadUserCampaigns()
    }
  }

  const addOwner = async (e) => {
    e.preventDefault();
    console.log('selected owner', selectedOwner)
    try {
      await fetch(`${API_SERVICE_BACKEND_2}/hubspot-owners-save`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userEmail: user.email,
          type: INTEGRATIONTYPE.HUBSPOT,
          compain_id: undefined,
          owner_id: selectedOwner,
          hubspotOwnerAssignment: hubspotOwnerAssignment
        }),
      });
      openSnackbar("Default owner assigned for all agents.", "success");
    } catch (error) {
      console.error("Error updating isAuto:", error);
    }
  }

  const openSnackbar = (message, severity = "success") => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setBarOpen(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setBarOpen(false);
  };

  useEffect(() => {
    const tokenZoho = user?.integrations?.zoho?.access_token;
    const zohoLastAutoExecution = user?.integrations?.zoho?.lastAutoExecution;
    const formattedZohoLastAutoExecution = moment(zohoLastAutoExecution).format(
      "MMMM Do YYYY, h:mm:ss a"
    );
    if (tokenZoho) {
      setHasZohoIntegration(true);
      setZohoIntegration(user?.integrations?.zoho);
      setIsZohoAuto(user?.integrations?.zoho?.isAuto);
      setZohoAutoExecution(formattedZohoLastAutoExecution);
    } else {
      setHasZohoIntegration(false);
    }

    const tokenHubspot = user?.integrations?.hubspot?.access_token;
    const hubspotLastAutoExecution =
      user?.integrations?.hubspot?.lastAutoExecution;
    const formattedHubspotLastAutoExecution = moment(
      hubspotLastAutoExecution
    ).format("MMMM Do YYYY, h:mm:ss a");
    if (tokenHubspot) {
      setHasHubSpotIntegration(true);
      setHubSpotIntegration(user?.integrations?.hubspot);
      setIsHubspotAuto(user?.integrations?.hubspot?.isAuto);
      setHubspotAutoExecution(formattedHubspotLastAutoExecution);
    } else {
      setHasHubSpotIntegration(false);
    }

    console.log({ user });
  }, [user]);

  useEffect(() => {
    (async () => {
      const { dateTime } = await getServerDateTime();
      const now = moment.tz(dateTime, APP_TIMEZONE);

      if (
        moment.tz(hubSpotIntegration?.expires_at, APP_TIMEZONE).unix() <
        now.unix()
      ) {
        setHubSpotExpired(true);
      } else {
        setHubSpotExpired(false);
      }

      if (hubSpotIntegration?.hubspotOwnerAssignment) {
        sethubspotOwnerAssignment(hubSpotIntegration?.hubspotOwnerAssignment)
      }

      if (hubSpotIntegration?.defaultHubspotContactsOwner) {
        setSelectedOwner(hubSpotIntegration?.defaultHubspotContactsOwner)
      }

      if (
        moment.tz(zohoIntegration?.expires_at, APP_TIMEZONE).unix() < now.unix()
      ) {
        setZohoExpired(true);
      } else {
        setZohoExpired(false);
      }
    })();
  }, [hubSpotIntegration, zohoIntegration]);

  useEffect(() => {

    const getOwnersList = async () => {
      try {
        const res = await fetch(`${API_SERVICE_BACKEND_2}/hubspot-contacts?email=${email}&refresh_token=${user?.integrations?.hubspot?.refresh_token}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }).then(response => response.json());

        setHubspotUsers(res.results)
      } catch (error) {
        console.error("Error contacts list:", error);
      }
    }

    const defContacts = async () => {
      if (hubSpotIntegration) {
        getOwnersList()
      }
    }
    defContacts()
  }, [hubSpotIntegration])

  return (
    <Card sx={{ overflow: "visible", border: "1px solid #EAECF0"}} className="glass-wrapper">
      <CardContent>
        <div
          ref={section9Ref}
          id="section9"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginBottom: "15px",
            }}
          >
            <>
              {/* <img
                src={LinkIcon}
                style={{ marginRight: "10px", height: "23px" }}
              /> */}
              <AiOutlineLink
                style={{
                  marginRight: "4px",
                  height: "23px",
                  fontSize: "42px",
                  color: theme.palette.text.primary,
                }}
              />
            </>
            <Typography
              sx={{
                fontWeight: "700",
                fontSize: "16px",
                color: theme.palette.text.primary,
              }}
            >
              Integrations
            </Typography>
          </div>
          <Divider />

          <div style={{ marginTop: "20px" }}>

            {/* Pipedrive Settings Section */}

            <PipedriveIntegrationSettings updateIntegrationAutoSyncFlag={updateIntegrationAutoSyncFlag} openSnackbar={openSnackbar} />

            {/* Zapier Settings Section */}
            <ZapierIntegrationSettings />
            <SalesforceIntegrationSettings />

            {/* Zoho Settings Section */}
            <Card style={{ marginTop: '1rem' }} className="glass-wrapper-50">
              <CardContent style={{ padding: "24px" }}>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginBottom: "8px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <img
                      style={{ marginRight: "10px" }}
                      width="28"
                      height="28"
                      src="https://uploads-ssl.webflow.com/594d8d64dc2b583adddea915/5a7fd806c7942700013399da_zoho.png"
                      alt="external-hubspot-a-developer-and-marketer-of-software-products-logo-color-tal-revivo"
                    />
                    <Typography
                      sx={{
                        fontWeight: "700",
                        fontSize: "16px",
                        color: theme.palette.text.primary,
                      }}
                    >
                      Zoho
                    </Typography>
                  </div>

                  {
                    hasZohoIntegration ? (
                      <div
                        style={{
                          textAlign: "center",
                          display: "flex",
                          alignItems: "center",
                          color: zohoExpired ? "red" : "green",
                        }}
                      >
                        <Button
                          variant="outlined"
                          onClick={initiateZohoOAuth}
                          sx={{
                            color: theme.palette.text.primary,
                            border: "1px solid #ECE9F5",
                            marginRight: "5px",
                            marginLeft: "5px",
                            padding: "5px 10px",
                            fontSize: "0.8em"
                          }}
                          className="glass-button-secondary-25"
                        >
                          <>
                            {/* <img
                              src={LinkIcon}
                              style={{ marginRight: "8px" }}
                            /> */}
                            <AiOutlineLink
                              style={{
                                marginRight: "4px",
                                height: "23px",
                                fontSize: "38px",
                                color: theme.palette.text.primary,
                              }}
                            />
                          </>
                          Reconnect
                        </Button>
                        <Button
                          variant="outlined"
                          onClick={() =>
                            handleDisconnect(
                              user?.dbRef?.email,
                              INTEGRATIONTYPE.ZOHO
                            )
                          }
                          sx={{
                            color: theme.palette.text.primary,
                            border: "1px solid #ECE9F5",
                            marginRight: "10px",
                            marginLeft: "5px",
                            padding: "5px 10px",
                            fontSize: "0.8em"
                          }}
                          className="glass-button-secondary-25"
                        >
                          <>
                            {/* <img
                              src={Linkbroken}
                              style={{ marginRight: "8px" }}
                            /> */}
                            <PiLinkBreakBold

                              style={{
                                marginRight: "8px",

                                fontSize: "26px",
                                color: theme.palette.text.primary,
                              }}
                            />
                          </>
                          Disconnect
                        </Button>
                      </div>
                    ) : <Button
                      variant="text"
                      onClick={initiateZohoOAuth}
                      size="small"
                      sx={{
                        color: theme.palette.text.primary,
                        border: "1px solid #ECE9F5",
                        marginLeft: "5px",
                        width: "max-content",
                        padding: "5px 10px",
                        fontSize: "0.8em"
                      }}
                      className="glass-button-secondary-25"
                    >
                      {/* <img
                        src={LinkIcon}
                        style={{ marginRight: "8px" }}
                      /> */}
                      <>
                        <AiOutlineLink
                          style={{
                            marginRight: "4px",
                            height: "23px",
                            fontSize: "38px",
                            color: theme.palette.text.primary,
                          }}
                        />


                      </>
                      Connect
                    </Button>
                  }
                </div>

                <Divider/>
                <div style={{ display: "flex", margin: "16px 0px" }} >
                  {
                    hasZohoIntegration ? (
                      <Grid container spacing={2} justifyContent={"space-between"} alignItems={"center"}>
                        <Grid item md={4}>
                          <Typography fontSize={"14px"} fontWeight={400}>{'Auto Transfer Leads'}</Typography>
                        </Grid>

                        {
                          hasZohoIntegration &&
                            isZohoAuto &&
                            zohoAutoExecution !== "Invalid date" ? (
                            <Grid item md={4} sx={{ textAlign: "center" }}>
                              <Typography
                                style={{
                                  color: theme.palette.text.primary,
                                  fontWeight: "bold",
                                }}
                                fontSize={12}
                              >
                                Last Auto Sync
                              </Typography>
                              <Typography fontSize={12} style={{ color: theme.palette.text.secondary }}>
                                {zohoAutoExecution}
                              </Typography>
                            </Grid>
                          ) : null
                        }

                        <Grid item md={4} sx={{ textAlign: "right" }}>
                          <Switch
                            checked={isZohoAuto}
                            onChange={async ({
                              target: { checked },
                            }) => {
                              setIsZohoAuto(checked);
                              try {
                                await updateIntegrationAutoSyncFlag(
                                  user.email,
                                  INTEGRATIONTYPE.ZOHO,
                                  checked
                                );
                              } catch (ex) {
                                console.log({ ex });
                              }
                            }}
                            value={INTEGRATIONTYPE.ZOHO}
                          />
                        </Grid>
                      </Grid>
                    ) : null
                  }

                </div>
              </CardContent>
            </Card>

            {/* HubSpot Settings Section */}
            <Card style={{ marginTop: '1rem' }} className="glass-wrapper-50">
              <CardContent style={{ padding: "24px" }}>
                <div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      // flexDirection: "row",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <img
                        style={{ marginRight: "10px" }}
                        width="28"
                        height="28"
                        src="https://img.icons8.com/external-tal-revivo-color-tal-revivo/48/external-hubspot-a-developer-and-marketer-of-software-products-logo-color-tal-revivo.png"
                        alt="external-hubspot-a-developer-and-marketer-of-software-products-logo-color-tal-revivo"
                      />
                      <Typography
                        sx={{
                          fontWeight: "700",
                          fontSize: "16px",
                          color: theme.palette.text.primary,
                        }}
                      >
                        Hubspot
                      </Typography>
                    </div>

                    {hasHubSpotIntegration ? (
                      <div
                        style={{
                          textAlign: "center",
                          display: "flex",
                          alignItems: "center",
                          color: hubspotExpired ? "red" : "green",
                        }}
                      >
                        {/* <Typography style={{ fontSize: "13px" }}>
                                  Expires:
                                  {moment
                                    .tz(
                                      hubSpotIntegration.expires_at,
                                      APP_TIMEZONE
                                    )
                                    .format("MM/DD/YYYY hh:mm A z")}
                                </Typography> */}
                        {/* <Button
                          variant="outlined"
                          onClick={() => setIsDrawerOpen(true)}
                          sx={{
                            color: theme.palette.text.primary,
                            border: "1px solid #ECE9F5",
                            marginRight: "5px",
                            marginLeft: "5px",
                            padding: "5px 10px",
                            fontSize: "0.8em",
                            display: "flex",
                            alignItems: "center",
                            gap: "5px"
                          }}
                        >
                          <MdOutlineHistory
                            style={{
                              fontSize: "18px",
                              color: theme.palette.text.primary,
                            }}
                          />
                          View Logs
                        </Button> */}
                        <Button
                          variant="outlined"
                          href={`${API_SERVICE_BACKEND_2}/oauth?userEmail=${email}`}
                          sx={{
                            color: theme.palette.text.primary,
                            border: "1px solid #ECE9F5",
                            marginRight: "5px",
                            marginLeft: "5px",
                            padding: "5px 10px",
                            fontSize: "0.8em"
                          }}
                          className="glass-button-secondary-25"
                        >
                          <>

                            <AiOutlineLink
                              style={{
                                marginRight: "4px",
                                height: "23px",
                                fontSize: "38px",
                                color: theme.palette.text.primary,
                              }}
                            />

                          </>
                          Reconnect
                        </Button>

                        <Button
                          variant="outlined"
                          onClick={() =>
                            handleDisconnect(
                              user?.dbRef?.email,
                              INTEGRATIONTYPE.HUBSPOT
                            )
                          }
                          sx={{
                            color: theme.palette.text.primary,
                            border: "1px solid #ECE9F5",
                            marginRight: "10px",
                            marginLeft: "5px",
                            padding: "5px 10px",
                            fontSize: "0.8em"
                          }}
                          className="glass-button-secondary-25"
                        >
                          <>
                            {/* <img
                              src={Linkbroken}
                              style={{ marginRight: "8px" }}
                            /> */}
                            <PiLinkBreakBold

                              style={{
                                marginRight: "8px",

                                fontSize: "26px",
                                color: theme.palette.text.primary,
                              }}
                            />

                          </>
                          Disconnect
                        </Button>
                      </div>
                    ) : <Button
                      variant="text"
                      href={`${API_SERVICE_BACKEND_2}/oauth?userEmail=${email}`}
                      size="small"
                      sx={{
                        color: theme.palette.text.primary,
                        border: "1px solid #ECE9F5",
                        marginLeft: "5px",
                        width: "max-content",
                        padding: "5px 10px",
                        fontSize: "0.8em"
                      }}
                      className="glass-button-secondary-25"
                    >
                      <>
                        {/* <img
                          src={LinkIcon}
                          style={{ marginRight: "8px" }}
                        /> */}
                        <AiOutlineLink
                          style={{
                            marginRight: "4px",
                            height: "23px",
                            fontSize: "38px",
                            color: theme.palette.text.primary,
                          }}
                        />


                      </>
                      Connect
                    </Button>
                    }
                  </div>
                  {
                    hasHubSpotIntegration &&
                    <>
                      <Divider style={{ marginTop: "8px" }}/>
                      <div style={{ display: "flex", margin: "16px 0px" }}>
                        {
                          hasHubSpotIntegration ? (
                            <Grid container spacing={2} justifyContent={"space-between"} alignItems={"center"}>
                              <Grid item md={4}>
                                <Typography fontSize={"14px"} fontWeight={400}>{'Auto Transfer Leads'}</Typography>
                              </Grid>

                              {
                                hasHubSpotIntegration &&
                                  isHubspotAuto &&
                                  hubspotAutoExecution !== "Invalid date" ? (
                                  <Grid item md={4} sx={{ textAlign: "center" }}>
                                    <Typography
                                      style={{
                                        color: theme.palette.text.primary,
                                        fontWeight: "bold",
                                      }}
                                      fontSize={12}
                                    >
                                      Last Auto Sync
                                    </Typography>
                                    <Typography fontSize={12} style={{ color: theme.palette.text.secondary }}>
                                      {hubspotAutoExecution}
                                    </Typography>
                                  </Grid>
                                ) : null
                              }

                              {/* <FormGroup>
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={isHubspotAuto}
                                      onChange={async ({
                                        target: { checked },
                                      }) => {
                                        setIsHubspotAuto(checked);
                                        try {
                                          await updateIntegrationAutoSyncFlag(
                                            user.email,
                                            "hubspot",
                                            checked
                                          );
                                        } catch (ex) {
                                          console.log({ ex });
                                        }
                                      }}
                                      value={"hubspot"}
                                    />
                                  }
                                  label="Auto Transfer Leads"
                                  labelPlacement="start"
                                />
                              </FormGroup> */}

                              <Grid item md={4} sx={{ textAlign: "right" }}>
                                <Switch
                                  checked={isHubspotAuto}
                                  onChange={async ({
                                    target: { checked },
                                  }) => {
                                    setIsHubspotAuto(checked);
                                    try {
                                      await updateIntegrationAutoSyncFlag(
                                        user.email,
                                        INTEGRATIONTYPE.HUBSPOT,
                                        checked
                                      );
                                    } catch (ex) {
                                      console.log({ ex });
                                    }
                                  }}
                                  value={INTEGRATIONTYPE.HUBSPOT}
                                />
                              </Grid>
                            </Grid>
                          ) : null
                        }

                      </div>
                      <Card
                        style={{
                          boxShadow: "none",
                          background: "transparent",
                          //border: "1px solid #ddd"
                        }}

                      >
                        <CardContent sx={{p: 0}}>
                          <FormControl>
                            <RadioGroup
                              row
                              aria-labelledby="ownertype-group-label"
                              name="ownertype-group"
                              defaultValue={hubspotOwnerAssignment}
                            >
                              <FormControlLabel value={HUBSPOTOWNERASSIGNMENTTYPE.DEFAULT} control={<Radio onChange={(e) => changeOwnerAssignmentStatus(e.target.value)} />} label="Use Single Default Owner for All Agents" checked={HUBSPOTOWNERASSIGNMENTTYPE.DEFAULT == hubspotOwnerAssignment} />
                              <FormControlLabel value={HUBSPOTOWNERASSIGNMENTTYPE.SEPARATE} control={<Radio onChange={(e) => changeOwnerAssignmentStatus(e.target.value)} />} label="Use Different Owner for Different Agents" checked={HUBSPOTOWNERASSIGNMENTTYPE.SEPARATE == hubspotOwnerAssignment} />
                            </RadioGroup>
                          </FormControl>
                        </CardContent>

                        <Divider/>

                        <CardContent sx={{p: 0}}>
                          {
                            hubspotOwnerAssignment === HUBSPOTOWNERASSIGNMENTTYPE.DEFAULT &&
                            <form onSubmit={addOwner}>
                              <Grid container spacing={2}>
                                <Grid item xs={12} md={6} >
                                  <FormControl sx={{ maxWidth: "300px", width: "100%" }}>
                                    <InputLabel id="select-owner-label">Select Owner</InputLabel>
                                    <Select
                                      labelId="select-owner-label"
                                      id="select-owner"
                                      value={selectedOwner}
                                      label="Select Owner"
                                      className="glass-dropdown"
                                      onChange={(e) => {
                                        setSelectedOwner(e.target.value)
                                      }}
                                    >
                                      {
                                        hubspotUsers?.map((hubspotUser, index) => {
                                          return <MenuItem key={index} value={hubspotUser.id}>{hubspotUser.firstName}</MenuItem>
                                        })
                                      }
                                    </Select>
                                  </FormControl>
                                </Grid>
                              </Grid>

                              <Button
                                type="submit"
                                variant="contained"
                                size="small"
                                color="primary"
                                sx={{
                                  mt: 1,
                                  ml: "auto",
                                  borderRadius: "8px",
                                  backgroundColor: theme.palette.primary.main,
                                  color: "white",
                                  "&:hover": {
                                    backgroundColor: "theme.palette.primary.main",
                                  },
                                }}
                                startIcon={<Person />}
                              >
                                Save Changes
                              </Button>
                            </form>
                          }

                          {
                            hubspotOwnerAssignment === HUBSPOTOWNERASSIGNMENTTYPE.SEPARATE && (
                              userCampaignLoading === false ? <DifferentOwners userCampaigns={userCampaigns} hubspotUsers={hubspotUsers} props={{
                                userEmail: user.email,
                                type: INTEGRATIONTYPE.HUBSPOT,
                                hubspotOwnerAssignment: hubspotOwnerAssignment
                              }}
                                openSnackbar={openSnackbar}
                              /> : <div style={{ textAlign: "center" }}><CircularProgress /></div>
                            )
                          }
                        </CardContent>
                      </Card>
                    </>
                  }
                </div>
              </CardContent>
            </Card>
          </div>
        </div>

        <Snackbar
          open={Baropen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
        >
          <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} style={{ color: "white" }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>

        <LogDrawer open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)} />


      </CardContent>
    </Card>
  );
};

export default IntegrationSettings;