import React, { useEffect, useState } from 'react'
import {
  Box,
  Card,
  CardContent,
  Divider,
  Stack,
  Switch,
  Typography,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Grid,
  CircularProgress,
  IconButton,
  Tooltip,
  useTheme
} from "@mui/material";
import { INTEGRATIONTYPE } from "../integrations"
import { API_SERVICE_BACKEND } from "src/config"
import { useAuth } from 'src/contexts/auth/firebase-context';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ZapierLogoSVG from "./zapier-logo"
import { Link } from 'react-router-dom';

const ZapierIntegrationSettings = () => {

  const { user } = useAuth()

  const [hasZapierIntegration, setHasZapierIntegration] = useState(false)
  const [open, setOpen] = React.useState(false);
  const theme=useTheme();

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const addZapierApiKey = async (email, checked) => {
    try {
      await fetch(`${API_SERVICE_BACKEND}/store-zapier-key`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email,
          checked
        }),
      });
    } catch (error) {
      console.error("Error updating isAuto:", error);
    }
  };

  const copy = () => {
    let copyText = user?.integrations?.zapier?.zapierApiKey;

    // Copy the text inside the text field
    navigator.clipboard.writeText(copyText);
  }

  useEffect(() => {
    setHasZapierIntegration(user?.integrations?.zapier?.zapierVerifiedFlag === 'Y')
  }, [user])

  return (
    <Card style={{ marginTop: '1rem' }} className="glass-wrapper-50">
      <CardContent style={{ padding: "24px" }}>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "8px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <ZapierLogoSVG />
            <Link
              to={'/zapier-instructions'}
              style={{
                fontSize: "16px",
                color: theme.palette.text.primary,
                textDecoration: "none",
                marginLeft: "8px"
              }}
            >
              Instructions
            </Link>
          </div>

          <Switch
            checked={hasZapierIntegration}
            onChange={async ({
              target: { checked },
            }) => {
              setHasZapierIntegration(checked)
              try {
                await addZapierApiKey(
                  user.email,
                  checked
                );
              } catch (ex) {
                console.log({ ex });
              }
            }}
            value={INTEGRATIONTYPE.ZAPIER}
          />
        </div>

        {
          hasZapierIntegration ? (
            <>
            <Divider/>
            <div style={{ display: "flex", margin: "16px 0px" }}>
              <Grid container spacing={2} justifyContent={"space-between"} alignItems={"center"}>
                <Grid item xs={12}>
                  <Typography fontSize={"12px"} fontWeight={600} color={theme.palette.text.secondary}>{'Zapier Api Key'}</Typography>
                  <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} style={{
                    boxShadow: "rgb(5 12 70 / 40%) 0px 0px 10px 0px",
                    borderRadius: "10px",
                    background: "rgb(30 37 101)",
                    color: "rgb(229 229 229)",
                    marginTop: '0.5rem'
                  }}>
                    <Typography 
                      padding={"8px 16px"} 
                      maxWidth={"calc(100% - 40px)"}
                      overflow={"auto"}
                      style={{fontSize:'14px'}}
                    >
                      {user?.integrations?.zapier?.zapierApiKey.substring(0, 100) + '...'}
                    </Typography>
                    <Tooltip open={open} onClose={handleClose} onOpen={handleOpen} title="Copy zapier api key.">
                      <IconButton style={{
                        background: "rgb(76 83 148 / 66%)",
                        margin: "8px"
                      }}
                      onClick={() => copy()}
                      >
                        <ContentCopyIcon style={{color: '#fff'}} />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Grid>
              </Grid>
            </div>
            </>
          ) : null
        }
      </CardContent>
    </Card>
  )
}

export default ZapierIntegrationSettings